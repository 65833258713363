
import { Vue, Component, Prop } from "vue-property-decorator";
import AssignmentTask from "@/components/task/assignment/AssignmentTask.vue";
import { mapState } from "vuex";
import GroupSessionLayout from "@/components/task/layouts/GroupSessionLayout.vue";

@Component({
  components: { GroupSessionLayout, AssignmentTask },
  computed: {
    ...mapState("session", ["session"]),
  },
})
export default class AssignmentActivityPage extends Vue {
  @Prop({ required: true, type: String })
  sessionId!: string;
}
