
import { Component, Prop, Vue } from "vue-property-decorator";
import Room from "@/components/task/assignment/components/Room.vue";
import { UFliterals } from "@/utils/literals";
import { webSocketUrl } from "@/env";
import { mapActions, mapMutations, mapState } from "vuex";
import { findRoom, getScore } from "@/utils/assignment";
import { IConstraint } from "@/schemas/activities/roomAssignment/IConstraint";
import { ActivityType, Scope } from "@/schemas/Enums";
import AgreementSubmitButton from "@/components/task/common/AgreementSubmitButton.vue";

@Component({
  name: "RoomAssignmentTask",
  components: { AgreementSubmitButton, Room },
  computed: {
    ...mapState("user", ["userId"]),
    ...mapState("roomAssignment", [
      "rooms",
      "subjects",
      "payoff",
      "constraints",
      "assignment",
    ]),
  },
  methods: {
    ...mapMutations("roomAssignment", {
      set_assignment: "SET_ALL_ASSIGNMENT",
    }),
    ...mapActions("session", ["submitSessionResult"]),
    ...mapMutations({ setSnack: "SET_SNACK" }),
  },
})
export default class RoomAssignmentTask extends Vue {
  @Prop({ required: true, type: String })
  sessionId!: string;
  actionWS: WebSocket | null = null;
  violatedConstraintsIds: [] = [];

  created(): void {
    this.launchTask();
  }

  launchTask(): void {
    this.openWebSockets();
    this.actionWS.onerror = (error) => {
      console.log(`Error with room assignment websocket`);
      console.log(error);
      this.openWebSockets();
    };
  }

  openWebSockets(): void {
    this.actionWS = new WebSocket(
      `${webSocketUrl}/room-assignment/${this.sessionId}/${this.userId}`
    );
    this.actionWS.onopen = function () {
      console.log(
        "Successfully connected to the room assignment action websocket endpoint."
      );
    };
    this.actionWS.onclose = () => {
      console.log("Room assignment websocket connection closed");
    };

    this.actionWS.onmessage = this.actionMessageHandler;
  }

  actionMessageHandler(message: {
    target: WebSocket;
    isTrusted: boolean;
    data: string;
    origin: string;
    lastEventId: string;
    srcElement: WebSocket;
  }): void {
    let parsed = JSON.parse(message.data);
    this.set_assignment(JSON.parse(parsed.assignment));
    this.checkConstraints();
  }

  closeWS(): void {
    if (this.actionWS) {
      this.actionWS.close(1000);
    }
  }

  isViolatedConstraint(constraint: IConstraint): boolean {
    return this.violatedConstraintsIds.includes(constraint.id);
  }

  getUF(key: string): string {
    return UFliterals[key] || "";
  }

  eventLogHandler(event: Record<string, any>): void {
    this.actionWS?.send(JSON.stringify(event));
    this.checkConstraints();
  }

  checkConstraints(): void {
    this.violatedConstraintsIds = [];
    this.constraints.forEach((constraint: IConstraint) => {
      const firstStudentRoom = findRoom(this.assignment, constraint.pair[0]);
      const secondStudentRoom = findRoom(this.assignment, constraint.pair[1]);

      if (firstStudentRoom !== "deck" && secondStudentRoom !== "deck") {
        switch (constraint.type) {
          case 0:
            // Must be in the same room
            if (firstStudentRoom !== secondStudentRoom) {
              // console.log(
              //   constraint.pair.join(" and "),
              //   "Must be in the same room"
              // );
              this.violatedConstraintsIds.push(constraint.id);
            }
            break;
          case 1:
            // Can't live in the same room
            if (firstStudentRoom === secondStudentRoom) {
              // console.log(
              //   constraint.pair.join(" and "),
              //   "Can't live in the same room"
              // );
              this.violatedConstraintsIds.push(constraint.id);
            }

            break;
          case 2:
            // Should be neighbors
            if (Math.abs(firstStudentRoom - secondStudentRoom) !== 1) {
              // console.log(
              //   constraint.pair.join(" and "),
              //   "Should be neighbors"
              // );
              this.violatedConstraintsIds.push(constraint.id);
            }

            break;
          case 3:
            // Can't live in the same room or be neighbors
            if (Math.abs(firstStudentRoom - secondStudentRoom) < 2) {
              // console.log(
              //   constraint.pair.join(" and "),
              //   "Can't live in the same room or be neighbors"
              // );
              this.violatedConstraintsIds.push(constraint.id);
            }
            break;
        }
      }
    });
  }

  get isSubmitDisable(): boolean {
    return !(
      this.assignment["deck"] &&
      this.assignment["deck"].length <= 0 &&
      this.violatedConstraintsIds.length <= 0
    );
  }

  submitResults(): void {
    let result = {
      scope: Scope.Group,
      session_id: this.sessionId,
      type: ActivityType.Assignment,
      score: this.score,
    };
    this.submitSessionResult({
      result,
      resultType: ActivityType.Assignment,
      next: () => {
        this.closeWS();
        this.$router.push(`/`);
        this.setSnack({
          snackColor: "info",
          snackTest: `La session est terminée.`,
        });
      },
    });
  }

  get score(): number {
    return getScore(this.payoff, this.assignment, 0);
  }
}
