export const findRoom = (
  assignment: Record<number | string, string[]>,
  student: string
) => {
  return Object.keys(assignment).find((room) =>
    assignment[room].includes(student)
  );
};

export const getScore = (
  payoff: Record<string, Record<string | number, number>>,
  assignments: Record<number | string, string[]>,
  nViolations: number
): number => {
  let score = 0;
  Object.keys(assignments).forEach((room) => {
    assignments[room].forEach((student) => {
      score += payoff[student][room];
    });
  });
  return score - nViolations * 100;
};
